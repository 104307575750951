import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Text = makeShortcode("Text");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <Text sx={{
      fontSize: [4, 5, 6],
      fontWeight: `bold`,
      color: `heading`
    }} mdxType="Text">
  Build stuff that matters. 
    </Text>
    <p>{`Hi 👋 I'm Luke, I use design thinking, experience mapping and a "minimize user interactions" ethos to make software that people love. `}</p>
    <h3>{`❤️ Develop empathy`}</h3>
    <h3>{`✏️ Design with context`}</h3>
    <h3>{`⌚ Focus on the present`}</h3>
    <h3>{`🔮 Embrace change`}</h3>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      