import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Title = makeShortcode("Title");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <Title text="Work" mdxType="Title" />
    <p>{`Some case studies may require verification. Please request a password `}<a parentName="p" {...{
        "href": "https://airtable.com/shrr6ZHvSFAs0n4oA"
      }}>{`here`}</a>{`.`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://Shadowhouse.netlify.app"
        }}>{`Shadowhouse: A Pandemic Era Choose Your Own Adventure Game`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.notion.so/provolove/Case-Study-Twilio-Dev-Portal-854c566ac4aa4f3e9b56193c4d3bc55e"
        }}>{`Case Study: Enterprise Developer Portal`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.notion.so/provolove/Case-Study-Low-Code-API-Integration-2111c5b047d1455faaea2793b3d59f44"
        }}>{`Case Study: Low-code API Integration`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      